/*
css_estructura.css

lo que son botones, label, alert,  
similar al bootstrap
*/

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* redes y paginas web ---------------------------------------- */

.redes-btn-fill {
  height: 50px;
  width: 50px;
  text-align: center;

  border-radius: 50%;
  border: 0px solid black;
  color: var(--color-blanco-fuerte);
  margin-right: 4px;
}

.redes-btn-dangerN {
  background-color: var(--color-rojo-medio);
  border-color: var(--color-rojo-medio);
  transition: all 0.5s ease;
}
.redes-btn-dangerN:hover {
  background-color: var(--color-rojo-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-rojo-fuerte);

  transform: scale(1) rotate(360deg) translate(0px);
  -webkit-transform: scale(1) rotate(360deg) translate(0px);
}

.redes-btn-infoN {
  border-color: var(--color-celeste-medio);
  background-color: var(--color-celeste-medio);
  transition: all 0.5s ease;
}
.redes-btn-infoN:hover {
  background-color: var(--color-celeste-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-celeste-fuerte);

  transform: scale(1) rotate(360deg) translate(0px);
  -webkit-transform: scale(1) rotate(360deg) translate(0px);
}

.redes-btn-successN {
  background-color: var(--color-verde-medio);
  border-color: var(--color-verde-medio);
  transition: all 0.5s ease;
}
.redes-btn-successN:hover {
  background-color: var(--color-verde-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-verde-fuerte);

  transform: scale(1) rotate(360deg) translate(0px);
  -webkit-transform: scale(1) rotate(360deg) translate(0px);
}

.redes-btn-warningN {
  background-color: var(--color-amarillo-medio);
  border-color: var(--color-amarillo-medio);
  transition: all 0.5s ease;
}
.redes-btn-warningN:hover {
  background-color: var(--color-amarillo-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-amarillo-fuerte);

  transform: scale(1) rotate(360deg) translate(0px);
  -webkit-transform: scale(1) rotate(360deg) translate(0px);
}

.redes-btn-petroleoN {
  background-color: var(--color-petroleo-medio);
  border-color: var(--color-petroleo-medio);
  transition: all 0.5s ease;
}
.redes-btn-petroleoN:hover {
  background-color: var(--color-petroleo-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-petroleo-fuerte);

  transform: scale(1) rotate(360deg) translate(0px);
  -webkit-transform: scale(1) rotate(360deg) translate(0px);
}

.redes-btn-whatsapp {
  background-color: var(--color-whatsapp);
  border-color: var(--color-whatsapp);
  transition: all 0.5s ease;
}
.redes-btn-whatsapp:hover {
  background-color: var(--color-whatsapp);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-whatsapp);

  transform: scale(1) rotate(360deg) translate(0px);
  -webkit-transform: scale(1) rotate(360deg) translate(0px);
}
/* ------------------------------------------------------------ */
/* dangerN  infoN  successN  warningN ---------petroleoN   
dangerN  infoN   successN    warningN  -------cafeN
--------------------------------------------------------------- */
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* label ------------------------------------------------------ */
.label {
  padding: 3px 8px; /* 3px 8px; */
  margin-top: 5px;
  border-radius: 7px;  /* 12px */
  color: var(--color-blanco-fuerte);
  font-weight: 500;
  font-size: 0.75em;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1.5em;
}

.label-infoN {
  background-color: var(--color-celeste-medio);
}

.label-successN {
  background-color: var(--color-verde-medio);
}

.label-dangerN {
  background-color: var(--color-rojo-medio);
}

.label-warningN {
  background-color: var(--color-amarillo-medio);
}

.label-ruc {
  background-color: var(--color-ruc);
}

.label-producto {
  background-color: var(--color-producto);
}

.label-categsubcateg {
  background-color: var(--color-categsubcateg);
}


/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* icon ------------------------------------------------------- */
.icon-infoN {
  color: var(--color-celeste-medio);
}

.icon-successN {
  color: var(--color-verde-medio);
}

.icon-dangerN {
  color: var(--color-rojo-medio);
}

.icon-warningN {
  color: var(--color-amarillo-medio);
}

.icon-whatsapp {
  color: var(--color-whatsapp);
  font-size: 20px;
  margin-right: 4px;
}


.icon-datos {
  font-size: 20px;
  margin-right: 4px;
  color: var(--color-iconodato);
}

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* btn-fill --------------------------------------------------- */

/*
.btn-fill {
	height: 70px;
  width: 70px;
  text-align: center;
  
    border-radius: 50%; 
    color: var(--color-blanco-fuerte);
  
    margin-right: 4px;
}	
*/
.btn-fill {
  border-radius: 10px; /* 25px; */
  padding: 6px 14px; /*padding: 10px 10px;  //circulo */ /*   padding: 6px 16px; //rectangular */
  color: var(--color-blanco-fuerte);
  opacity: 1;
  filter: alpha(opacity=100);
  margin-right: 3px;
}

.btn-tam {
  padding: 20px 15px 10px 15px;
}

.btn-infoN {
  border-color: var(--color-celeste-medio);
  background-color: var(--color-celeste-medio);
}
.btn-infoN:hover {
  background-color: var(--color-celeste-fuerte);
  color: var(--color-blanco-fuerte); /* rgba(255, 255, 255, 0.85);*/
  border-color: var(--color-celeste-fuerte);
}

.btn-successN {
  background-color: var(--color-verde-medio);
  border-color: var(--color-verde-medio);
}
.btn-successN:hover {
  background-color: var(--color-verde-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-verde-fuerte);
}

.btn-dangerN {
  background-color: var(--color-rojo-medio);
  border-color: var(--color-rojo-medio);
}
.btn-dangerN:hover {
  background-color: var(--color-rojo-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-rojo-fuerte);
}

.btn-warningN {
  background-color: var(--color-amarillo-medio);
  border-color: var(--color-amarillo-medio);
}
.btn-warningN:hover {
  background-color: var(--color-amarillo-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-amarillo-fuerte);
}

.btn-cafeN {
  background-color: var(--color-cafe-medio);
  border-color: var(--color-cafe-medio);
}
.btn-cafeN:hover {
  background-color: var(--color-cafe-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-cafe-fuerte);
}

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* btn-simple ------------------------------------------------- */
.btn-simple {
  border-radius: 10px; /* 25px; */
  padding: 4px 14px;
  background-color: var(--color-blanco-fuerte);
}

.btn-infoNs {
  color: var(--color-celeste-medio);
  border: 2px solid var(--color-celeste-fuerte);
}
.btn-infoNs:hover {
  background-color: var(--color-celeste-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-celeste-fuerte);
}

.btn-successNs {
  color: var(--color-verde-medio);
  border: 2px solid var(--color-verde-fuerte);
}
.btn-successNs:hover {
  background-color: var(--color-verde-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-verde-fuerte);
}

.btn-dangerNs {
  color: var(--color-rojo-medio);
  border: 2px solid var(--color-rojo-fuerte);
}
.btn-dangerNs:hover {
  background-color: var(--color-rojo-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-rojo-fuerte);
}

.btn-warningNs {
  color: var(--color-amarillo-medio);
  border: 2px solid var(--color-amarillo-fuerte);
}
.btn-warningNs:hover {
  background-color: var(--color-amarillo-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-amarillo-fuerte);
}

.btn-cafeNs {
  color: var(--color-cafe-medio);
  border: 2px solid var(--color-cafe-fuerte);
}
.btn-cafeNs:hover {
  background-color: var(--color-cafe-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-cafe-fuerte);
}

.btn-categNs {
  color: var(--color-categ-medio);
  border: 2px solid var(--color-categ-fuerte);
}
.btn-categNs:hover {
  background-color: var(--color-categ-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-categ-fuerte);
}

.btn-lugarNs {
  color: var(--color-lugar-medio);
  border: 2px solid var(--color-lugar-fuerte);
}
.btn-lugarNs:hover {
  background-color: var(--color-lugar-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-lugar-fuerte);
}

.btn-promoNs {
  color: var(--color-promo-medio);
  border: 2px solid var(--color-promo-fuerte);
}
.btn-promoNs:hover {
  background-color: var(--color-promo-fuerte);
  color: var(--color-blanco-fuerte);
  border-color: var(--color-promo-fuerte);
}

.btn-extra {
  border-radius: 5px; /* 25px; */
  padding: 8px 28px;
  /* background-color: var(--color-blanco-fuerte);*/
}

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* alerta ----------------------------------------------------- */

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-infoN {
  color: var(--color-celeste-fuerte);
  background-color: var(--color-celeste-claro);
  border: 2px solid var(--color-celeste-medio);
}

.alert-successN {
  color: var(--color-verde-fuerte);
  background-color: var(--color-verde-claro);
  border: 2px solid var(--color-verde-medio);
}

.alert-dangerN {
  color: var(--color-rojo-fuerte);
  background-color: var(--color-rojo-claro);
  border: 2px solid var(--color-rojo-medio);
}

.alert-warningN {
  color: var(--color-amarillo-fuerte);
  background-color: var(--color-amarillo-claro);
  border: 2px solid var(--color-amarillo-medio);
}

.alert-cafeN {
  color: var(--color-cafe-fuerte);
  background-color: var(--color-cafe-claro);
  border: 2px solid var(--color-cafe-medio);
}

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* xxxxxx ----------------------------------------------------- */
