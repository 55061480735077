* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #222222;
  overflow: hidden;
}

.square {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: linear-gradient(green, blue);
  z-index: 2;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: green;
  border-radius: 50%;
}

.triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: blue;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  height: 100%;
}

.column-left,
.column-right {
  padding: 20px;
  color: #fff;
}

.column-right {
  text-align: right;
}

.main-content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  flex-grow: 1;
}

.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  overflow-x: scroll;
  padding: 10px;
}

.image-frame {
  flex: 0 0 auto;
  width: 150px;
  height: 150px;
  margin: 10px;
  position: relative;
  transition: transform 0.5s ease-in-out;
  transform-style: preserve-3d;
  perspective: 500px;
}

.image-frame.active {
  transform: scale(1.2);
}

.image-frame img {
  max-width: 100%;
  max-height: 100%;
  backface-visibility: hidden;
}

.column-left,
.column-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.carousel img {
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  object-fit: contain;
  transition: transform 0.5s ease-in-out;
}

.square,
.circle,
.triangle {
  position: absolute;
  width: 6px;
  height: 10px;
  background-color: blue;
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.2s ease-out;
}

.square {
  background-color: blue;
}

.triangle {
  background-color: green;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid;
}

.circle:hover,
.square:hover,
.triangle:hover {
  transform: scale(1.5);
}

@keyframes moveImage {
  0% {
    transform: translate(0, 0) rotate(0) scale(1);
  }
  25% {
    transform: translate(50px, 50px) rotate(45deg) scale(1.2);
  }
  50% {
    transform: translate(0, 100px) rotate(90deg) scale(0.8);
  }
  75% {
    transform: translate(-50px, 50px) rotate(135deg) scale(1.2);
  }
  100% {
    transform: translate(0, 0) rotate(180deg) scale(1);
  }
}

.parallax {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: #000;
  perspective: 1000px;
}

.square-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.square {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.1);
  animation: squareAnimation 10s infinite alternate;
}

.item-section {
  text-align: center;
}

h2 {
  font-size: 48px;
  margin-bottom: 20px;
}

p {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 20px;
}

@keyframes squareAnimation {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .image-section {
    justify-content: flex-start;
    padding: 0;
    overflow-x: auto;
  }

  .image-frame {
    width: 100px;
    height: 100px;
  }

  h2 {
    font-size: 32px;
  }

  p {
    font-size: 18px;
  }
}
