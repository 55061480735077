/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* categoria.js ----------------------------------------------- */
/*
.card-border a {
    text-decoration: none;
  }
  */
.card-categoria__enlace,
.card-categoria__enlace:hover {
  text-decoration: none;
}
.card-categoria {
  background-color: #ffffff;
  border-radius: 1rem;
  /* border: 1px solid rgba(37, 36, 36, 0.856);*/
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 1.5em;
}
.card-categoria__thumbnail {
  position: relative;
  border-radius: 1rem 1rem 0 0;
  /* border-radius: 15px;*/
  width: 100%;
  height: 11rem;
  overflow: hidden;
}
.card-categoria__img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.card-categoria__titulo {
  font-family: "Raleway", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--color-negro-medio);
  text-align: center;
  padding: 0.625em 0;
}

.card-categoria:hover {
  border-radius: 1rem;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* subCategoria.js -------------------------------------------- */
.card-subcategoria__enlace,
.card-subcategoria__enlace:hover {
  text-decoration: none;
}

.card-subcategoria {
  background-color: #ffffff;
  border-radius: 1rem;
  /*border-radius: 50%;*/
  /*border: 1px solid rgba(37, 36, 36, 0.856);*/
  text-align: center;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 1.5em;
}
.card-subcategoria__thumbnail {
  position: relative;
  width: 155px;
  height: 155px;
  /*border-radius: 15px 15px 0px 0px;*/
  border-radius: 50%;
  border: 5px solid var(--color-subcateg-border);
  background: #000;
  overflow: hidden;

  margin: 10px 0px;
  margin-left: auto;
  margin-right: auto;
}
.card-subcategoria__img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.card-subcategoria__titulo {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: var(--color-negro-medio);
  text-align: center;
  padding: 10px 0px;
}

.card-subcategoria:hover {
  border-radius: 1rem;
  /*border-radius: 50%;*/
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* empresa.js ------------------------------------------------- */

.card-empresa {
  /* background: rgb(42, 43, 42);*/
  /* border:2px solid #2f312f;*/
  border-radius: 1.875rem 1.875rem 0 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 1.5em;
}

.card-empresa__thumbnail {
  position: relative;
  display: flex;
  flex-direction: column;
  /* min-width: 0;
  word-wrap: break-word;*/
  background-color: #6e6c6c;
  /* background-clip: border-box;*/
  border-radius: 1.875rem 1.875rem 0 0;
  overflow: hidden;
}

.card-empresa__image {
  width: 100%;
  /*height: 175px;*/
  height: 185px;
  background-size: cover;
  background-position: center;
}

.card-empresa__boton {
  padding: 7px;
  /*width: 117px;
  height: 142px;*/
  background-color: var(--color-emp-boton-fondo);
  position: absolute;
  cursor: pointer; /* pointer;*/
  bottom: 0px; /*  bottom: -129px;   el div datos que deberia cubrir la imagen solo esta mostrando una parte de la imagen*/
  left: -115px; /*  left: -112px;   -102px;  */
  border: 2px solid var(--color-emp-boton-border);

  -webkit-transition: 0.5s all ease-in-out; /*permite una transicion de barrido hacia arriba y abajo*/
  -moz-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  -ms-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.card-empresa__boton:hover {
  /* bottom:0px; antes -129px; -de esta manera se desplaza los elementos */
  -webkit-transform: translateY(
    -30px
  ); /*//funciona igual que bottom:0px; pero translateY tiene mejor desempeño que bottom, top, right o left*/
  -moz-transform: translateY(-30px);
  -o-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  transform: translateX(+100px);
}

.card-empresa__btn-modal {
  background: none;
  border: none;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--color-emp-boton-texto);
}

.card-empresa__descripcion {
  /* height: 3.6rem;*/
  /*  overflow: auto;*/
  margin: 0px;
  padding: 5px;
  background-color: var(--color-emp-descripcion);
}
.card-empresa__nombre {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 1.1;
  /* text-transform: uppercase;*/
  font-size: 1.375rem;
  letter-spacing: 1px;
  font-weight: 600;
  color: var(--color-emp-descripcion-title);
}

.card-empresa:hover {
 /* border-radius: 1rem;*/
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}


/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* modalEmpresa.js -------------------------------------------- */
.modal-dialog {
  margin-top: 5rem;
}

.tab-row {
  margin: 0px 0px;
  background: #ffffff;
}

.paddingTab {
  padding: 1rem;
  /*margin:10px 15px 10px 50px;*/
}

.img-modal {
  padding: 0px;
  margin: 0px;
  /*background: aqua;*/
  place-self: center;
}

/* ptDatos.js ------------------------------------------------- */
.label-right {
  float: right;
}

/* ptPagweb.js ------------------------------------------------ */


/* ptDescripcion.js ------------------------------------------- */


/* ptProductos.js --------------------------------------------- */


/* ptTiendas.js ----------------------------------------------- */


/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */


/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* xxx ------------------------------------------------- */




/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* xxx ------------------------------------------------- */



