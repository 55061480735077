/* ---------------------- 
  permite que la letra sea mas pequeña en movil y grande en celular (%) 
  
  px-> absoluto (no cambia)
  rem -> relativo (html 16px)
  em -> se ajusta al tamaño de la fuente (font-size) -- se vea armonico
  
  font-size:REM
  padding, margin: EM (se ajusta al tamaño font-size)
  .btn-rem{
    font-size: 5rem;  ( 5*16px = 80px  -- default is 16px)
    padding: .5em;
    margin: 0.25em;
  }
  */

  @import url("https://fonts.googleapis.com/css2?family=Fraunces:wght@700&family=Montserrat:wght@500;700&display=swap");

  @import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
  
  
  /*
  font-family: 'Fraunces', serif;  //headings
  font-family: 'Montserrat', sans-serif; //p
  
  font-family: 'Patrick Hand', cursive;
  
  */
  
  :root {
    --dark-cyan: hsl(158, 36%, 37%);
    --fondo: #ffffff;
    /*--fondo: hsl(30, 38%, 92%);*/
  
    --very-dark-blue: hsl(212, 21%, 14%);
    --dark-grayish-blue: hsl(228, 12%, 48%);
    --white: #ffffff;
  
    --font-headings: "Fraunces", serif;
    /*--font-headings:  'Patrick Hand', cursive;*/
    --font-otras: "Raleway", sans-serif;
  
    --a-hover: #8709cb;
  /*
    --color-categ-fondo: #d7a3fb; 
    --color-categ-border: #8734c2; 
    --color-categ-letra: #8734c2; 
    --color-categimg-border: #e5e5e5;
  */
  
  --color-blanco-fuerte: #ffffff;
  --color-blanco-medio: #ffffff;
  --color-blanco-claro: #f8f2f2;
  
  --color-categ-fuerte: #8d3bab;
  --color-categ-medio: #a95ac6;
  --color-categ-claro: #ddbde8;


  --color-subcateg-border: rgb(201, 196, 197); /*#e5e5e5;*/
  --color-subcateg-letra: #8734c2;


  --color-a: #ffb727;
  --color-a-hover: #ffc85a;
  /*--color-toTop-hover: #ffc550;*/
  /*--color-portafolio: #f3a200;*/
  /*--color-subFooter-icono: var(--color-toTop-hover);*/
  --color-white: #fff;
  --color-plomo: #444444;
  --color-plomo-osc: #3b434a;
  /*--color-plomo-linea: #82909c;*/
  
  }
  
  
  html {
    /*font-size: 10px;   youtube*/
    font-size: 70%;
  }
  
  @media (min-width: 600px) {
    html {
      font-size: 100%;
    }
  }
  
  /* ---------------------- */
  
  *,
  p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background-color: var(--fondo);
    font-family: "Montserrat", sans-serif;
  }
  
  /* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ */
  
  

/* -------------------------------- */
/* vistas  --------------- */
.layout {
  /* width:  85%;*/
  width: 100%;
  margin: 0 auto;

}



  /* -------------------------------- */
  /* extra --------------- */
  .bg-section {
   /* background-color: green;*/
  }
  
 .cabecera-menu {
    margin-top: 0;
    padding: 70px 0 0 0;
    /*background-image: url("./../imagen/banner-bg.png");*/
  

    
    background: rgb(27, 27, 27);
  
    /* height: 30px;*/
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .item-section {
    padding: 40px 40px;
    /*background-color: pink;*/
    /*background-color: #fff; */
    background-color: var(--fondo);
    min-height: 600px;
  }



  /* -------------------------------- */
  /* Banner --------------- */