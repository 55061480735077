:root {
  
    --header-title: whitesmoke;
    --header-subtitle: #b61635;
    --header-paragraph: #eaeaea;
  }
  /* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ */
  

  .hheader {
    width: 100%;
    margin: 0 auto;
    display: grid;
    align-content: center;
  
    background: linear-gradient(to right, #d37e29, #530812);
    background-image: linear-gradient(120deg, rgba(213, 252, 121, 0.90), rgba(72, 231, 93, 0.80) );
    background-image: url("./../images/user.png");
    background-image: linear-gradient(120deg, rgba(213, 252, 121, 0.90), rgba(72, 231, 93, 0.80)), url("./../images/user.png");
  
  
  
  
    /*
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../img/conejito.jpg");
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%),
      url("../img/conejito.jpg");
    background-image: linear-gradient(to right, #8360c3, #2ebf91),
      url("../img/conejito.jpg");
    background-image: linear-gradient(to right, #8e2de2, #4a00e0),
      url("../img/conejito.jpg");
    background-image: linear-gradient(to right, #fc5c7d, #6a82fb),
      url("../img/conejito.jpg");
    background-image: linear-gradient(to right, #c6ffdd, #fbd786, #f7797d),
      url("../img/conejito.jpg");
    background-image: linear-gradient(to right, #fffbd5, #b20a2c),
      url("../img/conejito.jpg");
  
      */
  
    /*background-color: #53081244;  add fondo de color transparente css */
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  
    /*opacity: 0.6;*/
    /*min-height: 250px;
        height: 100%;
        */
  
    /*
          grid-template-columns: repeat(
            auto-fit,
            minmax(200px, 1fr)
          );
          */
    /* grid-template-columns: 20em 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  
    animation: fade 0.8 0.6 both ease-in;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 1em;
  }
  
  .hheader-categ {
    background-image: linear-gradient(to right, #fffbd5, #b20a2c),
      url("./../images/user.png");
  }
  .hheader-busqueda {
    background-image: linear-gradient(to right, #c6ffdd, #fbd786, #f7797d),
      url("./../images/user.png");
    background-image: linear-gradient(to right, #8d2de2b9, #4b00e085),
      url("./../images/user.png");
  }
  
  .hheader-subcateg {
    background-image: linear-gradient(to right, #c6ffdd, #fbd786, #f7797d),
      url("./../images/user.png");
  }
  .hheader-empresas {
    background-image: linear-gradient(to right, #fc5c7d, #6a82fb),
      url("./../images/user.png");
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .hheader__thumbnail {
    /*width: 100%;
           height: 100%;*/
    width: auto;
    height: 13rem;
    line-height: 2.5rem;
  
    /*width: 60%;
          height: 210px;
          padding: 1em;
          */
    margin: auto auto;
    overflow: hidden;
  }
  
  .hheader__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto auto;
    /* border-top-left-radius: 10px;
          border-top-right-radius: 10px;*/
    opacity: 0.8;
  }
  
  .hheader__texts {
    /*background-color: var(--white);*/
    /* background-color: greenyellow;*/
    padding: 1.5em 2em;
    text-align: center;
  
    align-items: center;
    justify-content: center;
  
    /*border-radius: 0 0 10px 10px;*/
  }
  .hheader__title {
    text-transform: uppercase;
    letter-spacing: 6px;
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--header-title);
  }
  .hheader__subtitle {
    /*font-family: var(--font-headings);*/
    text-transform: uppercase;
    letter-spacing: 5px;
  
    /*font-size: 2rem;*/
    font-size: 1.5rem;
    font-weight: 600;
    /* margin: 0.8em 0 0.5em;*/
    color: var(--header-subtitle);
    background: #fbf7f7;
    padding: 8px 0px;
    display: block;
  
    /*
          background-image: linear-gradient(90deg, #6bbdff, #6c5ad4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      */
  }
  
  .hheader__paragraph {
    font-size: 1rem;
    line-height: 1.5;
    /* margin-bottom: 1.8rem;*/
    color: var(--header-paragraph);
  }
  
  .hheader__btn {
    font-weight: 600;
    padding: 0.5em;
  }
  
  /*  @media (min-width: 667px) {  */
  @media (max-width: 426px) {
    
      .hheader__thumbnail {
          /*width: 100%;
                 height: 100%;*/
          width: auto;
          height: 13rem;
          line-height: 2.5rem;
        
          /*width: 60%;
                height: 210px;
                padding: 1em;
                */
         /* margin: auto auto;*/
         margin:0rem;
          overflow: hidden;
        }
    
    }
  
  
  /* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ */
  
  /* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ */
  
  /* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ */
  