/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* footer ----------------------------------------------------- */
.fondoUno {
  background-color: red;
}
.main-footerNav {
  background: var(--color-footer-fondo);
  font-size: 1rem;
  margin: 0;
  padding: 1.25rem 0 0 0;
}

.main-footerNav h2 {
  color: var(--color-footerNav-fuerte);
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.main-footerNav hr {
  border: 1px solid var(--color-footerNav-icono);
  padding: 0;
  margin: 0.5rem 0 0.5rem 0;
}

.main-footerNav .columna {
  /* padding-bottom: 1.25rem;*/
}

.main-footerNav .footer-info {
  display: flex;
}

.main-footerNav .footer-info img {
  width: 7.5rem;
  height: 4.375rem;
  border-radius: 0.5rem;
}
.main-footerNav .footer-info span {
  color: #fff;
  padding: 0 0.25rem 0 1rem;
  font-weight: 300;
  font-size: 1.125rem;
}

.main-footerNav .footer-info .iconoaqui {
  font-size: 1.5rem;
  padding: 0;
  color: #4cbee9;
  margin-right: -1rem;
}

.main-footerNav .icono {
  width: 1.625rem;
  height: 1.625rem;
  font-size: 1.75rem;
  text-align: center;
  color: var(--color-footerNav-icono);
  margin-right: 0.75rem;
}

.main-footerNav .lineaVertical {
  position: relative;
  padding-left: 0.5em;
  list-style: none;
  border-left: 0.25rem solid var(--color-footerNav-linea);
  color: var(--color-footer-texto);
  font-size: 0.75rem;
}

.main-footerNav ul {
  position: relative;
  padding-left: 0.5em;
  list-style: none;
  border-left: 3px solid var(--color-footerNav-linea);
}

.main-footerNav ul li a {
  text-decoration: none;
  color: var(--color-footer-texto);
  font-size: 0.75rem;
}

.main-footerNav ul li a:hover {
  text-decoration: none;
  color: var(--color-footerNav-fuerte);
  /* font-weight: 900;*/
  padding-left: 0.625rem;

  /*   background: green;
              border: 2px solid var(--color-email);
              color: var(--color-footerNav-fuerte);
              transform: scale(1) rotate(360deg) translate(0px);
              -webkit-transform: scale(1) rotate(360deg) translate(0px);
          
          */
}

.main-footerNav .social-links a {
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 0.625rem 0.625rem 0;
  text-align: center;
  line-height: 2.5rem;
  border-radius: 50%;
  color: #ffffff;
  /* border: 2px solid var(--color-youtube);*/
  transition: all 0.5s ease;
}

.main-footerNav .social-links a:hover {
  background: #ffffff;
  color: #000;
  transform: scale(1) rotate(360deg) translate(0px);
  -webkit-transform: scale(1) rotate(360deg) translate(0px);
}

.main-footer {
  background-color: var(--color-footer-fondo);
  margin: 0;
  padding: 0 0 0.625rem 0;
}

.main-footer p {
  font-size: 0.75rem;
  color: var(--color-footer-texto);
  margin: 0;
}

.main-footer .align-right {
  text-align: right;
}

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* xxxx ------------------------------------------------------ */

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* xxxx ------------------------------------------------------ */
