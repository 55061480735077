/* 
css_administracion -> son estilos para formulario y tablas
*/
/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* tablas ------- --------------------------------------------- */
.tab-scroll-vertical {
  height: 32rem;
  width: 95%;
  overflow-y: scroll;
  /*border:1px solid red;*/

  /* horizontal
    width: 200px; 
    white-space: nowrap;
    overflow-x: scroll;
    */
}

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* formulario   formulario__titulo formulario__body  formulario__msj-enviado ------------------------------------------------------- */

.formulario {
  /* margin: 30px 20px 20px 20px; */ /* extra de .seccion */
  border: 1px solid #6d6a6a;
  /*padding:0 10px;*/
  border-radius: 0px 0px 0.625rem 0.625rem;
  /* background: pink;*/
  box-shadow: 5px 5px 6px 2px rgb(106, 105, 107);
}

.formulario__titulo {
  /*margin-top: -30px;
     margin-bottom: 0.75rem;*/
  /* padding-right: 80px;*/
  /* display:none;*/
  margin: 0px 120px 0px 0px;
  margin-top: -1rem;
  margin-left: -1rem;
  padding: 1rem 1rem;

  font-size: 1.375rem;
  font-weight: 900;

  /* background: var(--color-reg-fondo);*/
  color: #8734c2;
  /*display: inline-block;*/
  background-color: #fff;

  border-radius: 0.25rem;
  border: 2px solid #8734c2;
  border-left: 0.625rem solid #8734c2;
}

.formulario__body {
  /*background: lightsalmon;*/
  margin: 0.625rem 0;
}

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* xxxx ------------------------------------------------------- */
/*---- extra o hay que cambiar*/
.formulario__msj-enviado {
  color: #30d30f;
  /*background:pink;*/
  font-size: 0.875rem;
}

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* msj en tablas ---------------------------------------------- */
/*
.padding-horizontal {
    padding: 0 1rem;
  }

.mensaje-array-vacio {
    border-left: 8px solid #8b4e53;
    font-size: 18px;
    font-weight: 600;  
  }
  
  .mensaje-carg {
    border-left: 8px solid #664d03;
    font-size: 18px;
    font-weight: 600;
  }

  */

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* xxxx ------------------------------------------------------- */
/*
.msj-error{
    
}
    */
