/*
css_root
*/
:root {
  /* estructura --------------------------- */

  --color-verde-fuerte: #5fb685;
  --color-verde-medio: #7ac29a;
  --color-verde-claro: #acd9c0;

  --color-celeste-fuerte: #4199b2;
  --color-celeste-medio: #68b3c8;
  --color-celeste-claro: #c3e1e9;

  --color-rojo-fuerte: #c82333;
  --color-rojo-medio: red;
  --color-rojo-claro: #f3c5ca;

  --color-amarillo-fuerte: #eaa40f;
  --color-amarillo-medio: #f3bb45;
  --color-amarillo-claro: #fae4b5;

  --color-cafe-fuerte: #281c04;
  --color-cafe-medio: #322305;
  --color-cafe-claro: #855d0d;

  --color-petroleo-fuerte: #43524b;
  --color-petroleo-medio: #54675e;
  --color-petroleo-claro: #b8c5bf;

  /*----- (1color)  -----*/
  --color-ruc: #61afc5;
  --color-producto: #b18199;
  --color-categsubcateg: #b18199;
  --color-iconodato: #61476a;
  --color-whatsapp: #00e676;

  /*----- btn-redes (3colores) -----*/
  --color-blanco-fuerte: #ffffff;
  --color-blanco-medio: #ffffff;
  --color-blanco-claro: #f8f2f2;

  --color-negro-fuerte: #3e3b3e;
  --color-negro-medio: #4d4a4e;
  --color-negro-claro: #959196;

  /* card-empresa */
  --color-emp-boton-border: #967ddf;
  --color-emp-boton-fondo: #471087;
  --color-emp-boton-texto: #ffffff;
  --color-emp-descripcion: #f5efef;
  --color-emp-descripcion-title: #2e2e2e;

  /* body --------------------------------- */

  /* header ------------------------------- */

  /* footer ------------------------------- */
  --color-footer-texto: #bbb;
  --color-footer-fondo: #24262b;

  --color-footerNav-fuerte: #f0f1f1;
  --color-footerNav-icono: #f0f1f1;
  --color-footerNav-linea: #009eda;

  /* principal ---------------------------- */
  /*----- btn grande fondo (2)  -----*/

  --color-fondo1-fuerte: #657b61;
  --color-fondo1-medio: #80977b;
  --color-fondo1-claro: #ccd5ca;

  --color-fondo2-fuerte: #7f2e78;
  --color-fondo2-medio: #9f3996;
  --color-fondo2-claro: #e0a8db;

  /*----- btn grande (3colores)  -----*/
  --color-categ-fuerte: #8d3bab;
  --color-categ-medio: #a95ac6;
  --color-categ-claro: #ddbde8;

  --color-lugar-fuerte: #2a9193;
  --color-lugar-medio: #34b5b8;
  --color-lugar-claro: #aae5e7;

  --color-promo-fuerte: #ca280a;
  --color-promo-medio: #f33816;
  --color-promo-claro: #faafa2;

  /* -------------------------------------- */
  /* xxxxxx ------------------------------- */
  /* xxxxxx ------------------------------- */
  /* xxxxxx ------------------------------- */
}

/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */
/* xxxx ------------------------------------------------------- */
